.news-carousel {
  .block-date {
    display: flex;
    flex-direction: column;

    font-size: .875rem;
    white-space: nowrap;
    font-weight: bold;

    .big-date {
      font-family: "Monoton", cursive;
      color: #f5c332;
      font-size: 2.625rem;
      line-height: 2.25rem;
    }

  }

  .news-description {
    display: inline;
  }
}


  @media (max-width: 768px) {
    .news-carousel {

      .current-restaurant {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  }
