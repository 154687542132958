.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
}

.footer-logo {
  max-width: 150px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  display: inline;
  margin-right: 15px;
}

.footer-links li a {
  color: #fff;
}


.copyright {
  color: #6c757d;

  a {
    color: #6c757d;
  }
}