.restaurants-block {
  margin-top: 5rem;

  .title-block {
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: -3px;
    line-height: 4.0625rem;
  }

  .restaurant {
    position: relative; /* Для позиционирования overlay */
    overflow: hidden; /* Чтобы overlay не вылезало за пределы блока */

    .image-container {
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      transform: scale(0.9);
      transition: transform 0.3s ease; /* Анимация для наведения */
    }


    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
    }

    .overlay-content {
      text-align: center;
      color: white;
      font-size: 1.5rem;
    }

    &:hover .overlay {
      opacity: 1;
    }


    &:hover img {
      transform: scale(1); /* Увеличение картинки при наведении */
    }

  }
}

.reservation-block {
  margin-top: 5rem;
  background-color: #f9fafc;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  //padding: 0rem 0;
}

.news-block {
  margin-top: 5rem;
  background-color: #f9fafc;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 3rem 0;

  .news-img {
    width: 100%;
    max-width: 40rem;
  }
}


@media (max-width: 768px) {
  .restaurants-block .title-block {
    font-size: 3.4rem;
  }
}