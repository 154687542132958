/* App.css */

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}


a {
  //text-transform: uppercase;
  transition: all linear 0.2s 0.2s;

  &:hover {
    color: #f5c332;
  }
}
