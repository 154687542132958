.gallery-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 3.2rem;
  place-items: center;
}

.preview-img {
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease; /* Анимация для наведения */

  &:hover {
    transform: scale(1.1);
  }
}



@media (max-width: 768px) {
  .gallery-div {
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  }
}