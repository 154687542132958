header {
  font-size: .875rem;
  letter-spacing: 1px;



  .logo {
    img {
      max-width: 100%;
    }
  }

  .nav-link {
    text-transform: uppercase;
    transition: all linear 0.2s 0.2s;

    &:hover {
      color: #f5c332;
    }
  }

  .middle-header {
    border-top: solid 1px #f2f3f5;
    border-bottom: solid 1px #f2f3f5;

    box-shadow: 0 5px 5px 0 #f2f3f5;
  }

  .top-header {
    margin: 0.5rem 0;
    text-align: center;
  }
}