.blog-title {
  //color: black;

  .nav-link {
    text-transform: uppercase;
    transition: all linear 0.2s 0.2s;

    &:hover {
      color: #f5c332;
    }
  }
}